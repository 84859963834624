/*TODO: Caret styles should be removed once the react-bootstrap-table2 supports Bootstrap 4 */

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}

.dropdown .caret {
    border-bottom: 0;
    border-top: 4px dashed;
    border-top: 4px solid\9;
}

.react-bootstrap-table th .order>.dropup>.caret {
    margin: 10px 0;
    color: #cccccc;
}

.react-bootstrap-table th .order>.dropdown>.caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
}

.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret {
    margin: 10px 6.5px;
}

/* Caret styles end */

.react-select.is-invalid~.invalid-feedback {
    display: block;
}

.react-select.is-invalid>.Select-control {
    border-color: #f86c6b;
}

@media print {
    .main {
        margin: 0 !important;
    }

    .main * {
        visibility: hidden;
    }

    nav,
    .card-header,
    .sidebar,
    .navbar {
        display: none;
    }

    .invoice-box * {
        visibility: visible;
    }
}

/* Invoice box */

.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 0 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}

.invoice-box table tr.top table td .legal-box {
    border: 1px solid #999;
    padding: 10px 5px 5px 5px;
    font-weight: 600;
    color: #999;
}

.invoice-box table tr.top table td.title img {
    margin-bottom: 10px;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(4) {
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}

/* Invoice box end */

.table-mobile {
    /* Force table to not be like tables anymore */
    /* Smartphones (portrait and landscape) */
    /* iPads (portrait and landscape) */
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    .table-mobile {
        /* Hide table headers (but not display: none;, for accessibility) */
    }

    .table-mobile table,
    .table-mobile thead,
    .table-mobile tbody,
    .table-mobile th,
    .table-mobile td,
    .table-mobile tr {
        display: block;
    }

    .table-mobile thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .table-mobile tr {
        border: 1px solid #ccc;
    }

    .table-mobile td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px dashed #ccc;
        position: relative;
        text-align: right !important;
        min-height: 45px;
    }

    .table-mobile td:first-child {
        text-align: center !important;
        display: block;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        background: white;
        color: black;
    }

    .table-mobile td:last-child {
        border-bottom: none;
    }

    .table-mobile td:before {
        font-weight: bold;
        margin-right: 10px;
        content: attr(data-title);
        display: block;
        width: 45%;
        position: absolute;
        text-align: left;
    }

    .table-mobile td:first-child> :first-child {
        margin-left: 5px;
    }

    .table-mobile td ul {
        text-align: right !important;
    }

    .table-mobile td ul li {
        list-style: none;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .table-mobile body {
        padding: 0;
        margin: 0;
        width: 320px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .table-mobile body {
        width: 495px;
    }
}

.exportar-wrapper {
    float: right;
}

.exportar-wrapper div {
    display: inline;
    margin-right: 10px;
}

.exportar-wrapper .form-control {
    display: inline;
    margin-left: 5px;
    width: 100px;
}